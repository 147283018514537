.App {
  text-align: center;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* .carousel {
  width:100%;
  height:400px;
}

.carouselImages {
  width:100%;
  height:500px;
} */
